import {
	Box,
	Button,
	FormControlLabel,
	List,
	ListItem,
	Switch,
	Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { ChangeEvent, useEffect, useState } from "react";

import { functions, messaging } from "../../config/firebase";
import { Container } from "./styles";

const Settings: React.FC = () => {
	const categories = [
		"avvisi",
		"allerte",
		"comunità",
		"delibere",
		"ordinanze",
		"novità",
	] as const;

	const { enqueueSnackbar } = useSnackbar();

	const [subscribedTopics, setSubscribedTopics] = useState<string[]>([
		"allerte",
	]);

	useEffect(() => {
		const notificationPreferences = JSON.parse(
			localStorage.getItem("notificationPreferences") || `["allerte"]`,
		);
		setSubscribedTopics(notificationPreferences);
	}, []);

	const handleNotificationsUpdate = () => {
		if (!messaging) return;

		messaging
			.getToken({
				vapidKey:
					"BE1WnRJu1ITez38HrcZEqSQlVd4_QNyQUDZGRl8E7UXau1qRDMH4p600atqi58IIVdt4dMRO4WhkayGamXm1YOY",
			})
			.then((currentToken) => {
				if (currentToken) {
					const registerUserToken = functions.httpsCallable(
						"registerUserToken",
					);
					registerUserToken({
						token: currentToken || "",
						topics: subscribedTopics,
					});
					localStorage.setItem(
						"notificationPreferences",
						JSON.stringify(subscribedTopics),
					);
					enqueueSnackbar("Preferenze salvate");
				} else {
					Notification.requestPermission();
					console.log(
						"No registration token available. Request permission to generate one.",
					);
				}
			})
			.catch((error) => {
				console.log("An error occurred while retrieving token.", error);
			});
	};

	return (
		<Container>
			<Typography variant="h2">Impostazioni</Typography>
			<Box mt={3}>
				<Typography variant="h5">Notifiche</Typography>
				<Typography>
					Imposta le categorie di notizie per cui ricevere le notifiche push.
				</Typography>
				<List>
					{categories
						.filter((category) => category !== "allerte")
						.map((category) => (
							<ListItem key={category} disableGutters>
								<FormControlLabel
									control={
										<Switch
											color="primary"
											onChange={() => {
												if (subscribedTopics.includes(category))
													setSubscribedTopics(
														subscribedTopics.filter(
															(topic) => topic !== category,
														),
													);
												else
													setSubscribedTopics([...subscribedTopics, category]);
											}}
											checked={subscribedTopics.includes(category)}
										/>
									}
									label={category}
								/>
							</ListItem>
						))}
				</List>
				<Button
					variant="contained"
					color="primary"
					onClick={handleNotificationsUpdate}
				>
					Salva preferenze
				</Button>
			</Box>
		</Container>
	);
};

export default Settings;
