import { Skeleton } from "@material-ui/lab";
import React from "react";

import {
	ArticleBody,
	Container,
	Date,
	Description,
	Details,
	PictureSkeleton,
	Title,
} from "./styles";

export interface ArticleBriefSkeletonProperties {
	hasPicture?: boolean;
}

const ArticleBriefSkeleton: React.FC<ArticleBriefSkeletonProperties> = ({
	hasPicture,
}: ArticleBriefSkeletonProperties) => {
	return (
		<Container>
			{hasPicture && <PictureSkeleton />}
			<ArticleBody>
				<Details>
					<Date>
						<Skeleton width="40%" />
					</Date>
				</Details>
				<Title>
					<Skeleton width="80%" />
				</Title>
				<Description>
					<Skeleton width="100%" />
					<Skeleton width="60%" />
				</Description>
			</ArticleBody>
		</Container>
	);
};

export default ArticleBriefSkeleton;
