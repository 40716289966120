import firebaseCore from "firebase/app";

import "firebase/firestore";
import "firebase/auth";
import "firebase/analytics";
import "firebase/performance";
import "firebase/storage";
import "firebase/messaging";
import "firebase/functions";
import "firebase/remote-config";

export const firebaseConfig = {
	apiKey: "AIzaSyDiR-a3QVarlNk-qN4aTaqk6Wf6g3_3Olg",
	authDomain: "el1flem-bard.firebaseapp.com",
	databaseURL: "https://el1flem-bard.firebaseio.com",
	projectId: "el1flem-bard",
	storageBucket: "el1flem-bard.appspot.com",
	messagingSenderId: "127430232767",
	appId: "1:127430232767:web:a540797bb072e8f400140a",
	measurementId: "G-4XMT13CL0D",
};

const remoteConfigDefaultState = {
	shouldShowNewsCover: "true",
	novitàCover: "",
};

if (firebaseCore.apps.length === 0) {
	firebaseCore.initializeApp(firebaseConfig);
}

export const firebase = firebaseCore;
export const firestore = firebaseCore.firestore();
export const storage = firebaseCore.storage();
export const auth = firebase.auth();
export const functions = firebase.functions();
export const remoteConfig = firebase.remoteConfig();
export const messaging = firebase.messaging.isSupported()
	? firebase.messaging()
	: undefined;

if (!Boolean(auth.currentUser)) {
	firebase.auth().signInAnonymously();
}

remoteConfig.settings = {
	fetchTimeoutMillis: 1800000,
	minimumFetchIntervalMillis: 3600000,
};

remoteConfig.defaultConfig = remoteConfigDefaultState;

remoteConfig.fetchAndActivate();

if (messaging) {
	messaging.getToken({
		vapidKey:
			"BE1WnRJu1ITez38HrcZEqSQlVd4_QNyQUDZGRl8E7UXau1qRDMH4p600atqi58IIVdt4dMRO4WhkayGamXm1YOY",
	});

	messaging
		.getToken({
			vapidKey:
				"BE1WnRJu1ITez38HrcZEqSQlVd4_QNyQUDZGRl8E7UXau1qRDMH4p600atqi58IIVdt4dMRO4WhkayGamXm1YOY",
		})
		.then((currentToken) => {
			if (currentToken) {
				const registerUserToken = functions.httpsCallable("registerUserToken");
				registerUserToken({
					token: currentToken || "",
					topics: ["allerte"],
				});
				// sendTokenToServer(currentToken);
				// updateUIForPushEnabled(currentToken);
			} else {
				Notification.requestPermission();
				// Show permission request.
				console.log(
					"No registration token available. Request permission to generate one.",
				);
				// Show permission UI.
				// updateUIForPushPermissionRequired();
				// setTokenSentToServer(false);
			}
		})
		.catch((error) => {
			console.log("An error occurred while retrieving token.", error);
			// showToken("Error retrieving registration token. ", error);
			// setTokenSentToServer(false);
		});
}
