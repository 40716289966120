import { Theme } from "@material-ui/core";

const customTheme = {
	typography: {
		fontFamily: "Work Sans",
		h1: {
			fontFamily: "Rozha One",
			fontSize: "2.5rem",
			lineHeight: 1.5,
		},
		h2: {
			fontFamily: "Rozha One",
			fontSize: "2rem",
			lineHeight: 1.5,
		},
		h3: {
			fontFamily: "Rozha One",
			fontSize: "1.75rem",
			lineHeight: 1.5,
		},
		h4: {
			fontFamily: "Rozha One",
			fontSize: "1.5rem",
			lineHeight: 1.35,
		},
		h5: {
			fontFamily: "Rozha One",
			fontSize: "1.25rem",
			lineHeight: 1.35,
		},
		h6: {
			fontFamily: "Rozha One",
			fontSize: "1rem",
			lineHeight: 1.35,
		},
	},
} as Theme;

export const customLightTheme = {
	...customTheme,
	palette: {
		primary: {
			main: "#1395f2",
		},
		secondary: {
			main: "#F6CC2B",
		},
	},
} as Theme;

export const customDarkTheme = {
	...customTheme,
	palette: {
		primary: {
			main: "#fffdfd",
			light: "#fffdfd",
			dark: "#b2b1b1",
			contrastText: "#000000",
		},
		secondary: {
			main: "#d32f2f",
			light: "#ff6659",
			dark: "#9a0007",
			contrastText: "#ffffff",
		},
	},
} as Theme;

export function getCurrentTheme(type: "dark" | "light"): Theme {
	if (type === "dark") return customDarkTheme;
	if (type === "light") return customLightTheme;
	return customLightTheme;
}
