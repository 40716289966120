import { Container as MuiContainer } from "@material-ui/core";
import { darken, transparentize } from "polished";
import styled from "styled-components/macro";

const Container = styled(MuiContainer)`
	& > .MuiTypography-root {
		margin-bottom: ${({ theme }) => theme.spacing(1)}px;
	}

	overflow-x: hidden;
	padding-top: ${({ theme }) => theme.spacing(0)}px;

	a {
		text-decoration: none;
	}

	.Mui {
		&Alert {
			&-root {
				margin: ${({ theme }) => theme.spacing(0, -2, 3)};

				* {
					text-decoration: none;
				}
			}
		}
		&Tabs {
			&-root {
				background-color: ${({ theme }) => theme.palette.primary.main};
				margin: ${({ theme }) => theme.spacing(0, -2, 3)};
				min-height: 32px;
				padding: ${({ theme }) => theme.spacing(1, 0)};

				${({ theme }) => theme.breakpoints.up("md")} {
					margin-left: ${({ theme }) => theme.spacing(-4)}px;
				}

				.MuiTab {
					&-root {
						border-radius: ${({ theme }) => theme.shape.borderRadius * 3}px;
						font-weight: 700;
						min-height: 32px;
						min-width: auto;
						padding: ${({ theme }) => theme.spacing(0, 2)};
						text-transform: uppercase;
						color: ${({ theme }) => theme.palette.secondary.main};
						opacity: 1;

						&:first-child {
							/* margin-left: ${({ theme }) => theme.spacing(2)}px; */
						}

						&:last-child {
							margin-right: ${({ theme }) => theme.spacing(2)}px;
						}

						&.Mui-selected {
							/* background-color: ${({ theme }) =>
								theme.palette.primary.main}; */
							color: ${({ theme }) => theme.palette.primary.contrastText};
						}
					}
				}
			}

			&-indicator {
				display: none;
			}
		}

		&List {
			&-root {
				${({ theme }) => theme.breakpoints.up("md")} {
					display: grid;
					grid-column-gap: ${({ theme }) => theme.spacing(3)}px;
					grid-row-gap: ${({ theme }) => theme.spacing(2)}px;
					grid-template-columns: repeat(auto-fill, minmax(304px, 1fr));
				}
			}
		}
	}
`;

export const Cover = styled.div`
	background-color: ${({ theme }) =>
		darken(0.2, theme.palette.background.default)};
	height: 192px;
	position: relative;

	${({ theme }) => theme.breakpoints.up("md")} {
		height: 256px;
	}

	&::before {
		background-image: linear-gradient(
			0deg,
			${({ theme }) => transparentize(0, theme.palette.background.default)}
				1.25rem,
			${({ theme }) => transparentize(1, theme.palette.background.default)}
		);
		bottom: 0;
		content: "";
		display: block;
		height: 75%;
		left: 0;
		position: absolute;
		width: 100%;
	}

	& > img {
		display: block;
		height: 100%;
		object-fit: cover;
		width: 100%;
	}

	& > .MuiTypography-root {
		bottom: ${({ theme }) => theme.spacing(0)}px;
		color: ${({ theme }) => theme.palette.text.primary};
		left: ${({ theme }) => theme.spacing(2)}px;
		position: absolute;
	}
`;

export { Container };
