import { Container as MuiContainer } from "@material-ui/core";
import styled from "styled-components/macro";

const Container = styled(MuiContainer)`
	& > .MuiTypography-root {
		margin-bottom: ${({ theme }) => theme.spacing(1)}px;
	}

	padding-top: ${({ theme }) => theme.spacing(2)}px;

	.Mui {
		&List {
			&-root {
				margin: ${({ theme }) => theme.spacing(0, -2)};
			}
		}

		&ListItem {
			&-root {
				color: inherit;
			}

			&-indicator {
				display: none;
			}
		}
	}
`;

export { Container };
