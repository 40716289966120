import { ListItem, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { transparentize } from "polished";
import styled, { css } from "styled-components/macro";

const Container = styled(ListItem).attrs({
	component: "article",
	button: true,
})<{ $gravity?: "high" | "medium" | "low" }>`
	box-sizing: content-box;
	padding: ${({ theme }) => theme.spacing(2, 1)};
	margin: ${({ theme }) => theme.spacing(0, -1)};
	position: relative;
	align-items: flex-start;

	${({ theme }) => theme.breakpoints.up("md")} {
		overflow: hidden;
		border-radius: ${({ theme }) => theme.shape.borderRadius * 6}px;
	}

	${({ theme }) => theme.breakpoints.down("sm")} {
		border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
	}

	${({ $gravity, theme }) =>
		$gravity &&
		css`
			&::before {
				${$gravity === "high" &&
				css`
					background-color: ${transparentize(0.8, theme.palette.error.main)};
				`}
				${$gravity === "medium" &&
				css`
					background-color: ${transparentize(0.8, theme.palette.warning.main)};
				`}
				content: "";
				display: block;
				height: 100%;
				left: ${theme.spacing(-2)}px;
				position: absolute;
				top: 0;
				width: calc(100% + ${theme.spacing(4)}px);
				z-index: -1;
			}

			svg {
				${$gravity === "high" &&
				css`
					color: ${transparentize(0.4, theme.palette.error.main)};
				`}
				${$gravity === "medium" &&
				css`
					color: ${transparentize(0.4, theme.palette.warning.main)};
				`}
				height: 80px;
				position: absolute;
				right: -28px;
				width: 80px;
				z-index: -1;
			}
		`};
`;

export const Details = styled.div`
	display: flex;
	margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const Date = styled(Typography)`
	font-size: 0.75rem;
	font-weight: 700;
	line-height: 1;
	opacity: 0.5;
`;

export const Categories = styled.div`
	margin-left: ${({ theme }) => theme.spacing(1)}px;
`;

export const Category = styled(Typography)`
	color: ${({ theme }) => theme.palette.primary.main};
	font-size: 0.75rem;
	font-weight: 700;
	line-height: 1;
	text-transform: uppercase;
`;

export const Picture = styled.img`
	border-radius: ${({ theme }) => theme.shape.borderRadius * 4}px;
	box-shadow: 0 2px 12px -8px ${({ theme }) => theme.palette.text.primary};
	display: block;
	flex-shrink: 0;
	height: 96px;
	margin-right: ${({ theme }) => theme.spacing(1.5)}px;
	object-fit: cover;
	width: 64px;
`;

export const PictureSkeleton = styled(Skeleton).attrs({
	variant: "rect",
})`
	border-radius: ${({ theme }) => theme.shape.borderRadius * 4}px;
	display: block;
	height: 96px;
	margin-right: ${({ theme }) => theme.spacing(1.5)}px;
	width: 64px;
`;

export const Title = styled(Typography).attrs({
	variant: "h5",
})`
	margin-bottom: ${({ theme }) => theme.spacing(0.5)}px;
`;

export const Description = styled(Typography).attrs({
	variant: "body2",
})`
	max-height: 2.5rem;
	overflow: hidden;
	opacity: 0.75;
`;

export const ArticleBody = styled.div`
	flex-grow: 1;
`;

export { Container };
