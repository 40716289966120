import { format } from "date-fns";
import { Exclamation } from "heroicons-react";
import React from "react";

import placeholderPicture from "../../assets/gonfalone.jpg";
import {
	ArticleBody,
	Categories,
	Category,
	Container,
	Date,
	Description,
	Details,
	Picture,
	Title,
} from "./styles";

export interface ArticleBriefProperties {
	picture?: string;
	title: string;
	description?: string;
	date: Date;
	categories?: string[];
	gravity?: "high" | "medium" | "low";
}

const ArticleBrief: React.FC<ArticleBriefProperties> = ({
	picture,
	title,
	description,
	date,
	categories,
	gravity,
}: ArticleBriefProperties) => {
	return (
		<Container $gravity={gravity}>
			<Picture src={picture ?? placeholderPicture} alt="" />
			<ArticleBody>
				<Details>
					<Date>{format(date, "dd MMM yyyy")}</Date>
					<Categories>
						{categories?.map((category) => (
							<Category key={category}>{category}</Category>
						))}
					</Categories>
				</Details>
				<Title>{title}</Title>
				<Description>{description}</Description>
			</ArticleBody>
			{gravity && categories?.includes("allerte") && <Exclamation />}
		</Container>
	);
};

export { default as ArticleBriefSkeleton } from "./skeleton";

export default ArticleBrief;
