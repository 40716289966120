import React from "react";
import {
	BrowserRouter as Router,
	Redirect,
	Route,
	Switch,
} from "react-router-dom";

import AppLayout from "../layouts/AppLayout";
import Article from "./Article";
import ArticleList from "./ArticleList";
import ContactList from "./ContactsList";
import PlaceList from "./PlaceList";
import Settings from "./Settings";

const Routes: React.FC = () => {
	return (
		<Router>
			<Switch>
				<Route path="/">
					<AppLayout>
						<Switch>
							<Route path="/articles/:articleId" component={Article} />
							<Route path="/articles" component={ArticleList} />
							<Route path="/contacts" component={ContactList} />
							<Redirect exact path="/places" to="/places/list" />
							<Route path="/places" component={PlaceList} />
							<Route path="/settings" component={Settings} />
							<Redirect path="/" to="/articles" />
						</Switch>
					</AppLayout>
				</Route>
			</Switch>
		</Router>
	);
};

export default Routes;
