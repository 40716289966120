import { BottomNavigation, Container as MuiContainer } from "@material-ui/core";
import { transparentize } from "polished";
import styled from "styled-components/macro";

import { NakedLink } from "../../helpers/components/NakedLink";

const Container = styled.div`
	/* padding-bottom: 80px; */

	.MuiAppBar-root {
		background-color: ${({ theme }) => theme.palette.background.default};
		box-shadow: 0 1px 0 ${({ theme }) => theme.palette.divider};

		img {
			display: block;
			height: 56px;
			margin-left: -16px;
		}

		.MuiTextField-root {
			flex-grow: 1;
		}

		.MuiInputBase-input {
			padding: ${({ theme }) => theme.spacing(1, 2)};
		}

		.MuiToolbar-root {
			margin: 0 auto;
			max-width: ${({ theme }) => theme.breakpoints.values.lg}px;
			width: 100%;
		}
	}
`;

export const DesktopContainer = styled(MuiContainer)`
	display: grid;
	grid-template-columns: min-content 1fr;
	min-height: calc(100vh - 64px);
`;

export const Sidebar = styled.aside`
	border-right: 1px solid ${({ theme }) => theme.palette.divider};
	padding-right: ${({ theme }) => theme.spacing(3)}px;
	padding-top: ${({ theme }) => theme.spacing(3)}px;
`;

export const SidebarLink = styled(NakedLink)<{ $active?: boolean }>`
	align-items: center;
	border-radius: 20px;
	color: ${({ $active, theme }) =>
		$active ? theme.palette.primary.main : "inherit"};
	display: flex;
	margin-bottom: ${({ theme }) => theme.spacing(1)}px;

	&:hover {
		background-color: ${({ theme }) =>
			transparentize(0.8, theme.palette.primary.main)};
		color: ${({ theme }) => theme.palette.primary.main};
	}

	padding: ${({ theme }) => theme.spacing(1, 2, 1, 1)};

	svg {
		height: 24px;
		margin-right: ${({ theme }) => theme.spacing(1)}px;
		width: 24px;
	}

	.MuiTypography-root {
		font-size: 1rem;
		font-weight: bold;
	}
`;

export const BottomNav = styled(BottomNavigation)`
	bottom: 0;
	box-shadow: 0 -4px 16px -16px ${({ theme }) => theme.palette.text.primary};
	left: 0;
	position: fixed;
	width: 100%;
`;

export { Container };
